import axios from "axios";
import cookies from "@/plugins/vueCookies";

// let baseURL = 'https://service.olebsai.com/api/';
let baseURL = 'https://api.olebsai.com/api/';

let baseURLUploadImage = 'https://image.olebsai.com/api/';
let token = cookies.get('_token') ?? null;

function setTokenFromCookie() {
    token = cookies.get('_token') ?? null;
}

const requestInterceptor = axios.interceptors.request.use(
    config => {
        setTokenFromCookie();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

const responseInterceptor = axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.error('Error:', error);
        return Promise.reject(error);
    }
);

function addInterceptorsForTokoByUser() {
    axios.interceptors.request.eject(requestInterceptor);
    axios.interceptors.response.eject(responseInterceptor);
    axios.interceptors.request.use(
        config => {
            setTokenFromCookie();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access. Redirecting to login...');
                logout();
                window.location.reload();
            } else {
                console.error('Error:', error);
            }
            return Promise.reject(error);
        }
    );
}

export function getToken() {
    return token;
}

export function registerWeb(payload) {
    return axios.post(baseURL + 'v1/user/register/seller', payload);
}

export function loginWeb(payload) {
    return axios.post(baseURL + 'login/web', payload);
}

export function loginOTP(payload) {
    return axios.post(baseURL + 'login/otp', payload);
}

export function getTokoByUser() {
    addInterceptorsForTokoByUser();
    return axios.get(baseURL + 'v1/toko/get-by-user');
}

export function logout() {
    cookies.remove('_token');
}

export function getProdukByToko(payload) {
    addInterceptorsForTokoByUser();
    const params = new URLSearchParams();
    for (const key in payload) {
        if (payload[key] !== undefined) {
            params.append(key, payload[key]);
        }
    }
    const url = `${baseURL}v1/produk/get-by-toko${params.toString() ? `?${params.toString()}` : ''}`;
    return axios.get(url);
}

export function getProdukById(produkId) {
    addInterceptorsForTokoByUser();
    return axios.get(baseURL + 'v1/produk/get-by-id/' + produkId);
}

export function getParentKategori(payload) {
    addInterceptorsForTokoByUser();
    const params = new URLSearchParams();
    for (const key in payload) {
        if (payload[key] !== undefined) {
            params.append(key, payload[key]);
        }
    }
    const url = `${baseURL}v1/master-kategori/get-all${params.toString() ? `?${params.toString()}` : ''}`;
    return axios.get(url);
}

export function getSubKategori(payload) {
    addInterceptorsForTokoByUser();
    const params = new URLSearchParams();
    for (const key in payload) {
        if (payload[key] !== undefined) {
            params.append(key, payload[key]);
        }
    }
    const url = `${baseURL}v1/master-sub-kategori/get-all${params.toString() ? `?${params.toString()}` : ''}`;
    return axios.get(url);
}

export function createProduct(payload) {
    addInterceptorsForTokoByUser();
    const url = `${baseURL}v1/produk/create`;
    return axios.post(url, payload);
}

export function editProduct(payload) {
    addInterceptorsForTokoByUser();
    const url = `${baseURL}v1/produk/edit`;
    return axios.post(url, payload);
}

export function deleteProduct(payload) {
    addInterceptorsForTokoByUser();
    const url = `${baseURL}v1/produk/delete`;
    return axios.post(url, payload);
}

export function uploadProductImage(payload, idProduct) {
    // addInterceptorsForTokoByUser();
    // const formData = new FormData();
    // formData.append('images', payload[0]);
    // console.log(formData)
    // const url = `${baseURLUploadImage}upload-image/${idProduct}`;
    // return axios.post(url, formData);

    addInterceptorsForTokoByUser();
    const formData = new FormData();
    console.log(payload)
    formData.append('images', payload);
    
    const url = `${baseURLUploadImage}upload-image/${idProduct}`;
    return axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function updateStore(payload) {
    addInterceptorsForTokoByUser();
    const url = `${baseURL}v1/toko/update`;
    return axios.post(url, payload);
}

export function getKurirAll() {
    addInterceptorsForTokoByUser();
    const url = `${baseURL}v1/master-kurir/get-all`;
    return axios.get(url);
}

export function getProvince() {
    addInterceptorsForTokoByUser();
    const url = `${baseURL}v1/raja-ongkir/get-propinsi`;
    return axios.get(url);
}

export function getState(id) {
    addInterceptorsForTokoByUser();
    const url = `${baseURL}v1/raja-ongkir/get-kota/${id}`;
    return axios.get(url);
}

export function getKecamatan(id) {
    addInterceptorsForTokoByUser();
    const url = `${baseURL}v1/raja-ongkir/get-kecamatan/${id}`;
    return axios.get(url);
}
export function instantUpdateProduct(payload) {
    addInterceptorsForTokoByUser();
    const params = new URLSearchParams();
    for (const key in payload) {
        if (payload[key] !== undefined) {
            params.append(key, payload[key]);
        }
    }
    const url = `${baseURL}v1/product/direct-edit}`;
    return axios.get(url);
}

export function getPesananToko(payload) {
    addInterceptorsForTokoByUser();
    const params = new URLSearchParams();
    for (const key in payload) {
        if (payload[key] !== undefined) {
            params.append(key, payload[key]);
        }
    }
    const url = `${baseURL}v1/pesanan/get-by-seller${params.toString() ? `?${params.toString()}` : ''}`;
    return axios.get(url);
}

export function completedPesananToko(payload) {
    addInterceptorsForTokoByUser();
  
    const url = `${baseURL}/v1/pesanan/selesai`;
    return axios.post(url, payload);
}

export function detailPesanan(headerId) {
    addInterceptorsForTokoByUser();
  
    const url = `${baseURL}/v1/pesanan/get-list-pesanan-by-header/${headerId}`;
    return axios.get(url);
}

export function getStatBySeller() {
    addInterceptorsForTokoByUser();
  
    const url = `${baseURL}/v1/pesanan/get-stat-by-seller`;
    return axios.get(url);
}


export default {
    registerWeb,
    loginWeb,
    loginOTP,
    getToken,
    logout,
    getTokoByUser,
    getProdukByToko,
    getProdukById,
    getParentKategori,
    getSubKategori,
    createProduct,
    deleteProduct,
    editProduct,
    uploadProductImage,
    updateStore,
    getKurirAll,
    getProvince,
    getState,
    getKecamatan,
    getPesananToko,
    completedPesananToko,
    detailPesanan,
    getStatBySeller
}