<template>
    <v-list color="primary">
        <v-list-item 
            class="text-left" 
            prepend-icon="mdi-view-dashboard" 
            title="Beranda"
            :to="{name: 'HomeView'}"
        >
        </v-list-item>
        <v-list-item 
            class="text-left" 
            prepend-icon="mdi-shopping" 
            title="Produk"
            :to="{name: 'ProductPage'}"
        >
        </v-list-item>
        <v-list-item 
            class="text-left" 
            prepend-icon="mdi-cart-arrow-down" 
            title="Pesanan"
            :to="{name: 'PesananPage'}"
        >
        </v-list-item>
    </v-list>
</template>
<script>
export default {
    name: 'MenuAside',
}
</script>