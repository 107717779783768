<template>
  <div>
    <div class="logo-login">
        <img src="../assets/images/logo-oleb.png" />
        <span class="title-logo">Olebsai</span>
    </div>
    

    <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
    >
        <v-alert
            type="error"
            variant="outlined"
            class="mb-4"
            v-if="showFailed"
        >
            {{ errorMessage }}
        </v-alert>

        <div class="text-subtitle-1 text-medium-emphasis">Email</div>
        <v-text-field
            density="compact"
            placeholder="Input email"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            v-model="email"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Password
            <a
                class="text-caption text-decoration-none text-red"
                href="#"
                rel="noopener noreferrer"
                target="_blank"
            >
            Lupa password?</a>
        </div>

        <v-text-field
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            placeholder="Input password"
            prepend-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:append-inner="visible = !visible"
            v-model="password"
        ></v-text-field>


        <v-btn
            block
            class="mb-8"
            color="primary"
            size="large"
            variant="tonal"
            @click="loginAction"
            :disabled="loading"
        >
        
            {{ loading ? 'Loading...' : 'Log In' }}
        </v-btn>

        <v-card-text class="text-center">
            <a
                class="text-red text-decoration-none"
                href="/register"
                rel="noopener noreferrer"
            >
                Daftar Penjual <v-icon icon="mdi-chevron-right"></v-icon>
            </a>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { loginWeb } from '@/api/ApiService.js';

export default {
    name: 'LoginPage',
    data() {
        return {
            visible: false,
            email: null,
            password: null,
            loading: false,
            errorMessage: '',
            showFailed: false
        }
    },
    methods: {
        async loginAction() {
            const payload = {
                email: this.email,
                password: this.password
            };

            this.loading = true;

            try {
                const res = await loginWeb(payload);
                const { token, role, otpFlag } = res.data.data;
                this.loading = false;

                if (role === 'SELLER') {
                    if (otpFlag) {
                        this.$cookies.set('_token', token);
                        setTimeout(() => {
                            location.reload();
                            this.$router.push({ name: 'HomeView' });
                        }, 1000);
                    } else {
                        this.$router.push(`/verifikasi-otp?email=${this.email}`);
                    }
                } else {
                    this.showError('Anda tidak terdaftar sebagai penjual');
                }
            } catch (e) {
                this.loading = false;
                this.showError(e.message);
                console.error(e);
            }
        },

        showError(message) {
            this.showFailed = true;
            this.errorMessage = message;
            setTimeout(() => {
                this.showFailed = false;
                this.errorMessage = '';
            }, 5000);
        }
    }
}
</script>
<style lang="scss" scoped>
.logo-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  
  img {
    height: 40px;
  }
  .title-logo {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>