<template>
  <TitlePage title="Beranda"/>
  <div class="need-fill" v-if="myProfile !== null">
    <v-alert
      type="warning"
      variant="outlined"
      class="mb-4"
      title="Penting"
      text="Harap melengkapi profil toko anda dan menunggu persetujuan oleh admin."
      v-if="myProfileActive"
    >
      <RouterLink to="/profile">Lengkapi profil</RouterLink>
    </v-alert>
  </div>

  <CardContent>
    <highcharts :options="chartOptions"></highcharts>
  </CardContent>
</template>

<script>
import TitlePage from '@/components/TitlePage.vue';
import CardContent from '@/components/CardContent.vue';
import { getStatBySeller } from '@/api/ApiService.js';
import Highcharts from 'highcharts';

// Set global Highcharts options
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
    decimalPoint: '.'
  }
});

export default {
  name: 'HomeView',
  components: {
    TitlePage,
    CardContent
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Statistik Pesanan'
        },
        xAxis: {
          categories: ['Baru', 'Dikemas', 'Dikirim', 'Selesai'],
          title: {
            text: 'Status Pesanan'
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Jumlah Pesanan',
            align: 'high'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              format: '{point.y}'
            },
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [{
          name: 'Jumlah',
          data: [0, 0, 0, 0], // Initial empty data
          color: '#7CB5EC'
        }],
        credits: {
          enabled: false
        },
        legend: {
          enabled: false // Hide legend since we only have one series
        }
      }
    }
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    async getChartData() {
      try {
        const res = await getStatBySeller();
        if (res.data.code === 200) {
          const stats = res.data.data;
          // Update the series data with values from API
          this.chartOptions.series[0].data = [
            stats.baru,
            stats.dikemas,
            stats.dikirim,
            stats.selesai
          ];
          
          // Optionally update the title to include shop name
          this.chartOptions.title.text = `Statistik Pesanan - ${stats.namaToko}`;
        }
      } catch(e) {
        console.error(e);
      }
    }
  },
  computed: {
    myProfileActive() {
      let my = null;
      my = this.$store.getters['getStoreProfile'];
      if (my) {
        return my.status !== 'AKTIF'
      }
      return my;
    }
  }
}
</script>

<style lang="scss" scoped>
.need-fill {
  margin: 0px 20px;
}
</style>