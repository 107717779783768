<template>
    <TitlePage title="Data Pesanan"/>
    <CardContent>
        <v-table density="compact" hover>
            <thead>
                <tr>
                    <th class="text-left">
                        Nama Pembeli
                    </th>
                    <th class="text-left">
                        Alamat Pembeli
                    </th>
                    <th class="text-left">
                        Status
                    </th>
                    <th class="text-left">
                        No Handphone
                    </th>
                    <th class="text-left">
                    </th>
                </tr>
            </thead>
            <tbody v-if="loadingPesanan">
                <tr>
                    <td colspan="5" style="text-align: center;">
                        <v-progress-circular class="mt-2" color="primary" model-value="20" indeterminate></v-progress-circular>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-if="dataPesanan.length == 0">
                    <td colspan="5" style="text-align: center;">Belum ada data</td>
                </tr>
                <tr 
                    v-for="(item, index) in dataPesanan"
                    :key="index"
                    v-else
                >
                    <td>{{ item.namaBuyer }}</td>
                    <td>{{ item.alamatBuyer }}, {{ item.kecamatanBuyer }}, {{ item.kotaBuyer }}, {{ item.kodePosBuyer }}</td>
                    <td>{{ item.nomorHpBuyer }}</td>
                    <td> 
                        <v-chip size="small" color="success" v-if="item.status === 'AKTIF'">
                            {{ item.status }}
                        </v-chip>
                        <v-chip size="small" color="warning" v-else>
                            {{ item.status }}
                        </v-chip>
                    </td>
                    <td>
                        <v-btn  
                            color="warning"
                            size="small"
                            style="margin-right: 8px;"
                            @click="openDetail(item.headerId)"
                        >
                            Detail
                        </v-btn>
                        <!-- <v-btn  
                            color="info"
                            size="small"
                            @click="openSelesaikan(item.headerId)"
                        >
                            Selesaikan
                        </v-btn> -->
                    </td>
                </tr>
            </tbody>
        </v-table>
    </CardContent>
    <ModalComponent 
        v-if="openModalSelesaikan"
    >
        <v-card
            class="mx-auto my-8"
            max-width="344"
            elevation="16"
        >
            <div class="dialogDelete">
                <div class="title">Selesaikan pesanan</div>
                <div class="content-dialog">Apakah anda ingin menyelesaikan pesanan ini?</div>
                <div class="button-options">
                    <v-btn 
                        variant="text" 
                        color="info"
                        @click="openModalSelesaikan = false"
                    >
                        Tidak
                    </v-btn>
                    <v-btn 
                        variant="flat" 
                        color="info"
                        :disabled="loadingDelete"
                        @click="completedOrder"
                    >
                        Ya, Selsaikan
                    </v-btn>
                </div>
            </div>
        </v-card>
    </ModalComponent>

    <ModalComponent
        v-if="isDetailOpen"
    >
        <v-card
            class="mx-auto my-8"
            max-width="800"
            elevation="16"
        >
            <div class="dialogDelete">
                <div class="title">Detail Pesanan</div>
                <div class="content-dialog">
                    <h4>Produk</h4>
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Nama produk
                                </th>
                                <th class="text-left">
                                    Qty
                                </th>
                                <th class="text-left">
                                    Harga satuan
                                </th>
                                <th class="text-left">
                                    Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in detailData.produk"
                                :key="index"
                            >
                                <td>{{ item?.namaProduk }}</td>
                                <td>{{ item?.qty}}</td>
                                <td>{{ item?.hargaSatuan}}</td>
                                <td>{{ item?.hargaTotal}}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </div>
                <div class="button-options">
                    <v-btn 
                        variant="text" 
                        color="info"
                        @click="isDetailOpen= false"
                    >
                        Tutup
                    </v-btn>
                </div>
            </div>
        </v-card>
    </ModalComponent>
</template>
<script>
import TitlePage from '@/components/TitlePage.vue';
import CardContent from '@/components/CardContent.vue';
import { getPesananToko, completedPesananToko, detailPesanan } from '@/api/ApiService.js';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
    name: 'PesananPage',
    data() {
        return {
            payload: {
                pageNumber: 1,
                pageSize: 10,
                sortDirection: 'desc',
                tokoId: null
            },
            dataPesanan: [],
            loadingPesanan: false,
            openModalSelesaikan: false,
            headerId: null,
            isDetailOpen: false,
            detailData: {
                pesananHeader: {},
                produk: []
            }
        }
    },
    components: {
        TitlePage,
        CardContent,
        ModalComponent
    },
    mounted() {
        this.fetchPesanan();
    },
    methods: {
        async fetchPesanan() {
            this.loadingPesanan = true;
            try {
                const res = await getPesananToko(this.payload);
                this.dataPesanan = res.data.data.data;
                this.loadingPesanan = false;
            } catch(e) {
                console.error(e);
            }
        },
        openSelesaikan(data) {
            this.selectedData = data;
            this.openModalSelesaikan = true;
        },
        async completedOrder() {
            const idPesanan = {
                pesananHeaderId: this.selectedData
            }
            try {
                await completedPesananToko(idPesanan);
                this.fetchPesanan();
               
            } catch(e) {
                console.error(e);
            }
        },
        openDetail(id) {
            this.isDetailOpen = true;
            console.log(id)
            this.callDetail(id);

        },
        async callDetail(id) {
            try {
                const response = await detailPesanan(id);
                this.detailData.pesananHeader = response.data.data.pesananHeader;
                this.detailData.produk = response.data.data.produk;
                console.log(response.data.data)
            } catch(e) {
                console.error(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dialogDelete {
    padding: 15px;
    .title {
        font-weight: 600;
        margin-bottom: 8px;
    }
    .content-dialog {
        font-weight: 400;
        margin-bottom: 15px;
    }
    .button-options {
        text-align: right;
    }
}
</style>