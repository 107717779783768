<template>
    <TitlePage title="Tambah Produk"/>
    <CardContent
        :isUseback="successSave"
        @action-back="backToList"
    >
        <div v-if="successSave">
            <v-alert
                type="success"
                title="Produk berhasil ditambahkan"
                text="Produk anda akan direview dan butuh persetujuan oleh Admin"
            ></v-alert>
        </div>
        <div class="form-add" v-else>
            <div style="margin-bottom: 30px;" v-if="showError">
                <v-alert
                    type="error"
                    title="Produk gagal ditambahkan"
                    :text="`Error: ${errorMessage}`"
                ></v-alert>
            </div>
            <v-row >
                <v-col cols="12" md="6" lg="6">
                    <v-text-field
                        label="Nama Produk"
                        class="input-form"
                        variant="underlined"
                        v-model="form.namaProduk"
                    ></v-text-field>

                    <v-select
                        label="Kategori"
                        :items="parentKategori"
                        item-title="namaKategori"
                        item-value="kategoriId"
                        v-model="selectedKategori"
                        return-object
                        variant="underlined"
                    ></v-select>

                    <v-select
                        :label="loadingSub ? 'Loading...' : 'Sub Kategori'"
                        :items="subKategori"
                        item-title="namaSubKategori"
                        item-value="subKategoriId"
                        v-model="selectedSubKategori"
                        return-object
                        variant="underlined"
                    ></v-select>

                    <v-text-field
                        label="Harga Normal"
                        class="input-form"
                        variant="underlined"
                        type="number"
                        v-model="form.hargaNormal"
                    ></v-text-field>

                    <v-text-field
                        label="Harga Promo"
                        class="input-form"
                        variant="underlined"
                        type="number"
                        v-model="form.hargaPromo"
                    ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="6">
                    <v-text-field
                        label="Stok"
                        class="input-form"
                        variant="underlined"
                        type="number"
                        v-model="form.qtyStock"
                    ></v-text-field>

                    <v-text-field
                        label="Berat (Dalam gram)"
                        class="input-form"
                        variant="underlined"
                        type="number"
                        v-model="form.beratGram"
                    ></v-text-field>

                    <v-textarea 
                        label="Deskripsi" 
                        variant="underlined"
                        class="input-form"
                        row="7"
                        v-model="form.deskripsi"
                    ></v-textarea>

                    <div v-for="index in lengthImage" :key="index">
                        <v-file-input
                            
                            :rules="rules"
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="Pilih Gambar produk"
                            prepend-icon="mdi-camera"
                            label="Gambar Produk"
                            v-model="imageProduct[index - 1]"
                        ></v-file-input>
                        <div class="add-item">
                            <v-btn class="left" color="error" @click="deleteForm(index)" v-if="lengthImage > 1">Delete</v-btn>
                            <v-btn class="right" @click="addForm">Add More</v-btn>
                        </div>
                    </div>
                    
                </v-col>
            </v-row>

            <div class="d-flex flex-row-reverse">
                <v-sheet>
                    <v-btn  
                        color="info"
                        @click="saveProduct"
                        :disabled="loadingSave"
                    >
                        <v-icon icon="mdi-content-save"></v-icon>
                        {{ loadingSave ? 'Menyimpan...' : 'Simpan Produk' }}
                    </v-btn>
                </v-sheet>
            </div>
        </div>
        
        
    </CardContent>
</template>
<script>
import TitlePage from '@/components/TitlePage.vue';
import CardContent from '@/components/CardContent.vue';
import { getParentKategori, getSubKategori, createProduct, uploadProductImage } from '@/api/ApiService.js';

export default {
    name: 'ProductPageAdd',
    data() {
        return {
            form: {
                beratGram: null,
                deskripsi: null,
                hargaNormal: null,
                hargaPromo: null,
                kategoriId: null,
                namaProduk: null,
                qtyStock: null,
                subKategoriId: null,
                tokoId: null,
            },
            selectedKategori: null,
            selectedSubKategori: null,
            parentKategori: [],
            subKategori: [],
            loadingSub: false,
            rules: [
                value => {
                    return !value || !value.length || value[0].size < 2000000 || 'Image size should be less than 2 MB!'
                },
            ],
            imageProduct: [],
            successSave: false,
            loadingSave: false,
            errorMessage: '',
            showError: false,
            lengthImage: 1
        }
    },
    watch: {
        selectedKategori: {
            deep: true,
            handler: function(value) {
                const kategoriId = value?.kategoriId;
                this.form.kategoriId = kategoriId;
                this.fetchSubKategori(kategoriId);
            }
        },
        selectedSubKategori: {
            deep: true,
            handler: function(value) {
                const subKategoriId = value?.subKategoriId;
                this.form.subKategoriId = subKategoriId;
            }
        }
    },
    components: {
        TitlePage,
        CardContent
    },
    mounted() {
        this.fetchParentKategori();
        this.form.tokoId = this.storeId;
    },
    methods: {
        deleteForm(index) {
            this.imageProduct.splice(index - 1, 1);
            this.lengthImage--;
        },
        addForm() {
            this.lengthImage += 1;
        },
        async fetchParentKategori() {
            const payload= {
                pageNumber: 1,
                pageSize: 100,
                sortBy: 'namaKategori',
                sortDirection: 'asc'
            };
            try {
                const res = await getParentKategori(payload);
                this.parentKategori = res.data.data.data;
            } catch(e) {
                console.error(e);
            }
        },
        async fetchSubKategori(kategoriId) {
            const payload= {
                pageNumber: 1,
                pageSize: 100,
                sortBy: 'namaSubKategori',
                sortDirection: 'asc',
                kategoriId: kategoriId
            };
            this.loadingSub = true;
            try {
                const res = await getSubKategori(payload);
                this.subKategori = res.data.data.data;
                this.loadingSub = false;
            } catch(e) {
                console.error(e);
            }
        },
        async saveProduct() {
            this.loadingSave = true;
            console.log(this.form.hargaPromo)
            if(this.form.hargaPromo == null) {
                this.form.hargaPromo = null
            }
            
            try {
                const res = await createProduct(this.form)
                const idProduct = res.data.data;
                this.uploadImageproduct(idProduct)
                this.successSave = true;
                this.loadingSave = false
            } catch(e) {
                this.showError = true;
                this.errorMessage = e.message;
                console.log('error', e)
                console.error(e);
                setTimeout(() => {
                    this.showError = false;
                    this.loadingSave = false;
                    this.errorMessage = '';
                }, 5000)
            }

        },
        backToList() {
            this.$router.push({name: 'ProductPage'});
        },

        async uploadImageproduct(idProduct) {
            try {
                for (let index = 0; index < this.imageProduct.length; index++) {
                    const item = this.imageProduct[index];
                    console.log(item[0], index, idProduct);
                    const res = await uploadProductImage(item, idProduct);
                    // Handle the response as needed
                    return res;
                }
            } catch(e) {
                console.error(e);
            }
            
        }
    },
    computed: {
        storeId() {
            let storeId = null;
            const storeProfile = this.$store.getters['getStoreProfile'];
            storeId = storeProfile?.tokoId;
            return storeId;
        }
    }
}
</script>
<style lang="scss" scoped>
.input-form {
    margin-bottom: 16px;
}
.add-item {
    width: 100%;
    margin-bottom: 40px;
    text-align: right;
    .left {
        margin-right: 15px;
    }
    .right {

    }
}
</style>